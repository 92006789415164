import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import {graphql, Link} from 'gatsby';
import "../styles.scss";
import {Helmet} from "react-helmet";
import HeroVideo from "../components/GeneralComponents/HeroVideo";
import FormUniversidade from "../components/UniversidadeIberica/FormUniversidade";
import {Modal} from "react-bootstrap";
import {Document, Page} from "react-pdf";

const UniversidadeIberica = ({data}) => {


    return (
        <>
            <Helmet>
                <html lang="pt-BR" amp/>
                <meta charSet="UTF-8"/>
                <title>Cia. Ibérica - Universidade Ibérica</title>
                <link rel="canonical" href="https://www.ciaiberica.com.br/universidade-iberica/"/>
                <meta name="robots" content="index,follow,max-snippet: -1,max-image-preview:large"/>
                <meta property="og:locale" content="pt-BR"/>
                <meta property="og:title" content="Cia. Ibérica - Universidade Ibérica"/>
                <meta property="og:description"
                      content="Conheça o Universidade Ibérica, o ambiente de estudos da Cia. Ibérica."/>
                <meta property="og:url" content="https://www.ciaiberica.com.br/universidade-iberica/"/>
                <meta property="og:sitename" content="Cia. Ibérica"/>
                <meta property="article:publisher" content="https://facebook.com/ibericacondutoreseletricosltda"/>
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
            </Helmet>
            <Header/>
            <HeroVideo props={data}/>
            <FormUniversidade />
            <Footer/>
        </>
    )
}

export const query = graphql`
    query UniversidadeIbericaQuery {
        video: contentfulAsset(title: {eq: "universidade-iberica"}) {
            file {
              url
              contentType
            }
        }
        poster: contentfulAsset(title: {eq: "universidade-iberica-poster"}) {
            file {
              url
              contentType
            }
        }
    }
`


export default UniversidadeIberica