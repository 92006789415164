import React, {Component} from "react";
import {Modal} from "react-bootstrap";
import {Document, Page} from "react-pdf";
import ReCAPTCHA from "react-google-recaptcha";
import {Link} from "gatsby";

const tokenRef = React.createRef();
export default class FormUniversidade extends Component {

    constructor(props) {
        super(props);
        this.createUser = this.createUser.bind(this);
        this.showModalIbericaPrivacidade = this.showModalIbericaPrivacidade.bind(this);
        this.state = {
            rcpTokStok: null,
            showModal: false,
            dildont: null
        };
    }

    async setRecaptchaToken() {

        const token = await tokenRef.current.getValue();
        this.setState({rcpTokStok: token})
    }

    createUser(event) {
        event.preventDefault();
        event.stopPropagation();

    }

    showModalIbericaPrivacidade(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({dildont: event});
        this.setState(prevCheck => ({showModal: !prevCheck.showModal}))
    }

    acceptTerms() {
        this.setState(prevCheck => ({showModal: !prevCheck.showModal}))
        this.createUser(this.state.dildont);
    }

    rejectTerms() {
        this.setState(prevCheck => ({showModal: !prevCheck.showModal}))
        this.setState({dildont: null});
    }

    render() {
        return (
            <>
                <div className="container">
                    <div className="row mt-3 mb-3 text-center">
                        <h2>Universidade Ibérica</h2>
                    </div>
                    <div className="row">
                        <p>A Universidade Ibérica é a área de aprendizado virtual da Cia. Ibérica. Nela, você pode
                            participar de diversos cursos, tais como Técnicas de Venda de Balcão, Eletricista Básico,
                            dentre
                            muitos outros. Os cursos oferecidos na Universidade Ibérica são <b>100%
                                gratuitos</b> e <b>todos dão direito a certificado no final do curso</b>.</p>
                        <p>Participe dos cursos da Universidade Ibérica e invista na sua formação profissional!</p>
                        <p>Se você já se cadastrou anteriormente na Universidade Ibérica, clique no botão abaixo para
                            acessar. Você será redirecionado(a) para a página de acesso da Universidade Ibérica:</p>
                        <a href="https://universidade.ciaiberica.com.br" rel="noopener,noreferrer" target="_blank"
                           className="btn btn-primary">Acessar Universidade Ibérica</a>
                    </div>
                    <hr className="mt-3 mb-3"/>
                    <div className="mt-3 mb-3">
                        <p>Caso você ainda não tenha cadastro na Universidade Ibérica, preencha os dados abaixo:</p>
                        <form onSubmit={this.showModalIbericaPrivacidade}>
                            <div className="row form-row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="name">Nome Completo:</label>
                                        <input type="text" className="form-control" placeholder="Seu nome aqui"
                                               id="name"
                                               required/>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="email">E-mail:</label>
                                        <input type="email" className="form-control" placeholder="usuario@email.com"
                                               id="email"
                                               required/>
                                    </div>
                                </div>
                                <ReCAPTCHA ref={tokenRef} sitekey="6LccbXIeAAAAADuTBnVY7crQ6EkgabX429_o6YOT" hl="pt-BR"
                                           size="invisible" badge="bottomright" onChange={this.setRecaptchaToken}/>
                            </div>
                            <div className="row form-row">
                                <button type="submit" className="btn btn-primary"
                                >Criar Usuário
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <Modal size="lg" show={this.state.showModal} onHide={this.showModalIbericaPrivacidade}>
                    <Modal.Header closeButton>
                        <Modal.Title>Criação de Usuário</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{
                        overflowX: "scroll",
                        msOverflowX: "scroll",
                        overflowY: "scroll",
                        msOverflowY: "scroll"
                    }}>
                        <p>Você está prestes a criar um usuário no Ambiente Virtual Cia. Ibérica. Confira a Política de
                            Privacidade Cia. Ibérica abaixo:</p>
                        <div className="container">
                            <div className="row mt-3 mb-3 text-center">
                                <h2>Política de Privacidade</h2>
                            </div>
                            <div className="row mt-3 mb-3">
                                <p>Esta Política de Privacidade têm como objetivo estabelecer as diretrizes do uso de
                                    dados
                                    pessoais pela Cia. Ibérica, bem como os critérios para utilização dos ambientes
                                    públicos da
                                    empresa.</p>
                                <p><b>1. DEFINIÇÕES BÁSICAS</b></p>
                                <p>Consideram-se, no âmbito desta Política de Privacidade, as seguintes definições:</p>
                                <ol className="list-group list-group-numbered">
                                    <li className="list-group-item"><i>Website:</i> sítio virtual hospedado de forma
                                        "estática" que apresenta as informações principais da Cia. Ibérica.
                                    </li>
                                    <li className="list-group-item"><i>Universidade Ibérica:</i> ambiente virtual de
                                        aprendizado
                                        para a realização de cursos e instruções.
                                    </li>
                                </ol>
                                <p><b>2. DA COLETA E DO USO DE DADOS PESSOAIS</b></p>
                                <p>A Cia. Ibérica coleta, no âmbito de suas páginas eletrônicas, os seguintes dados
                                    pessoais,
                                    fornecidos de forma voluntária e consciente pelo(a) usuário(a):</p>
                                <p><b>2.1. FORMULÁRIO DE CONTATO:</b></p>
                                <ul className="list-group">
                                    <li className="list-group-item">Nome</li>
                                    <li className="list-group-item">E-mail</li>
                                    <li className="list-group-item">Telefone</li>
                                    <li className="list-group-item">Cidade e Estado</li>
                                    <li className="list-group-item">CEP</li>
                                </ul>
                                <p><b>2.2. UNIVERSIDADE IBÉRICA</b></p>
                                <ul className="list-group">
                                    <li className="list-group-item">Nome</li>
                                    <li className="list-group-item">E-mail</li>
                                    <li className="list-group-item">Telefone</li>
                                    <li className="list-group-item">Cidade e Estado</li>
                                </ul>
                                <p>Os dados previstos no item 2.1. <b>não são utilizados para individualizar visitas ao
                                    site da
                                    Cia. Ibérica.</b></p>
                                <p>Já os dados previstos no item 2.2. são usados para a realização dos cursos no
                                    ambiente
                                    denominado "Universidade Ibérica", sendo requisitos mínimos para a utilização do
                                    ambiente virtual de aprendizado. Poderão ser utilizados também para a criação de
                                    métricas,
                                    envio de e-mail marketing e outros usos legais da Cia. Ibérica.</p>
                                <p>Os dados previstos em ambos os itens <b>não serão vendidos, transferidos e/ou cedidos
                                    à
                                    terceiros, sendo usados única e exclusivamente pela Cia. Ibérica em seus usos legais
                                    previstos neste item.</b></p>
                                <p><b>3. DA COLETA DE DADOS ANÔNIMOS DURANTE A NAVEGAÇÃO</b></p>
                                <p>A critério da Cia. Ibérica, poderão ser coletados dados estatísticos de navegação no
                                    website
                                    Cia. Ibérica, como também no ambiente "Universidade Ibérica". Os dados estatísticos
                                    coletados utilizam as plataformas Google Analytics e Google Tag Manager e seus
                                    dados, quando processados, são
                                    anônimos. Em outras palavras, a Cia. Ibérica <b>não trabalhará para retirar a
                                        anonimização
                                        dos dados descritos, tampouco os usará e/ou procurará usá-los em sua forma pura
                                        e
                                        identificada</b>.</p>
                                <a href="https://policies.google.com/privacy?hl=pt-BR" rel="noopener,noreferrer"
                                   target="_blank">Aplicam-se as políticas previstas na Política de Privacidade da
                                    empresa Google.</a>
                                <p><b><i>Última atualização: 10/05/2022</i></b></p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={this.acceptTerms}>Cancelar</button>
                        <button className="btn btn-primary" onClick={this.rejectTerms}>Criar Usuário</button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}